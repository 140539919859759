import '@/bootstrap'; 
import { CToaster, CToast } from '@coreui/vue'
import store from '@/store'
import router from '@/router'

const token = store.state.user.token;

const service = window.axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL+"/api",
  timeout: 100000, // Request timeout
});

service.interceptors.request.use(
  config => {
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    // Do something with request error
    console.log('Error code: '+error.response.status); // for debug
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    /*if (response.data.token) {
      $store.commit('user.setToken', response.data.token);
    }
    console.log(response);*/
    
    return response;
  },
  error => {
    
    /*let message = error.message;
    if (error.response.data && error.response.data.errors) {
      message = error.response.data.errors;
    } else if (error.response.data && error.response.data.error) {
      message = error.response.data.error;
    }*/

    if (error.response) {
      if(error.response.status === 401){
        store.commit('setToken', null);
        router.push('/pages/login');
      }
    }

    return Promise.reject(error);
  }
);

export default service;
